<template>
  <header class="header">
    {{ logo }}
  </header>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Header extends Vue {
  get logo(): string {
    return window.location.host.replace('.com', '')
  }

  mounted(): void {
    document.title = this.logo
  }
}
</script>

<style lang="scss">
.header {
  padding: 12px;
  text-align: center;
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 38px;
  font-weight: 700;
  line-height: 49px;
}

@media (max-width: 920px) {
  .header {
    padding: 7px;
    font-size: 20px;
    line-height: 26px;
  }
}
</style>
