<template>
  <div class="form">
    <Sent v-if="isSanded" :email="form.email" @back="reset" />
    <template v-else>
      <div class="form__title">
        Support Team will contact you <br />
        once you submit the form
      </div>

      <div class="form__item">
        <label class="form__label" for="name">
          Name
        </label>
        <input
          id="name"
          v-model="form.name"
          :class="{ form__input_error: error.name }"
          data-test-id="form-name your-name"
          class="form__input"
          type="text"
          maxlength="64"
          placeholder="Your name"
          @focus="clearErrors"
        />
        <div v-if="error.name" class="form__error">
          <template v-if="form.name.length <= 0">
            We need to know your name to be able to answer your request.
          </template>
          <template v-else>
            Your name should contain only alphabets not numbers.
          </template>
        </div>
      </div>
      <div class="form__item">
        <label class="form__label" for="email">
          Email
        </label>
        <div class="form__hint">
          The answer about payments will be sent to this email
        </div>
        <input
          id="email"
          v-model="form.email"
          :class="{ form__input_error: error.email }"
          data-test-id="form-email e-g-youremail-gmail-com"
          class="form__input"
          type="text"
          maxlength="64"
          placeholder="E.g. youremail@gmail.com"
          @focus="clearErrors"
        />
        <div v-if="error.email" class="form__error">
          <template v-if="form.email.length <= 0">
            Indicate your email to receive an answer.
          </template>
          <template v-else>
            Indicate your credible contact email
          </template>
        </div>
      </div>
      <div class="form__item">
        <label class="form__label" for="card">
          Card number
        </label>
        <div class="form__hint">
          First 6 and last 4 digits of the card number
        </div>
        <input
          id="card"
          v-model="form.card"
          v-mask="'#### ##** **** ####'"
          :class="{ form__input_error: error.card }"
          data-test-id="form-card xxxx-xx-xxxx"
          class="form__input"
          type="text"
          placeholder="XXXX  XX**  ****  XXXX"
          @focus="clearErrors"
        />
        <div v-if="error.card" class="form__error">
          To check the request we need your card number.
        </div>
      </div>
      <div class="form__item">
        <label class="form__label" for="comment">
          Comment
        </label>
        <input
          id="comment"
          v-model="form.comment"
          data-test-id="form-comment you-can-add-some-comments"
          class="form__input"
          type="text"
          maxlength="320"
          placeholder="You can add some comments"
        />
      </div>

      <div :class="{ form__confirm_error: error.confirm }" class="form__confirm">
        <label :class="{ form__checkbox_active: form.confirm }" class="form__checkbox">
          <input
            v-model="form.confirm"
            data-test-id="form-confirm"
            type="checkbox"
            hidden
            @change="clearErrors"
          />
        </label>
        <div class="form__terms">
          I confirm my contact information indicated above is accurate.
        </div>
      </div>

      <button class="form__btn" data-test-id="click:send send-request" @click="send">
        Send request
      </button>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

import apiTickets from '@/api/apiTickets'
import Sent from '@/components/Sent.vue'
import ValidateService from '@/services/ValidateService'

@Component({
  components: {
    Sent,
  },
  directives: {
    mask,
  },
})
export default class Form extends Vue {
  isSanded = false

  form = {
    name: '',
    email: '',
    card: '',
    comment: '',
    confirm: false,
  }

  error = {
    name: false,
    email: false,
    card: false,
    confirm: false,
  }

  send(): void {
    this.clearErrors()

    if (!ValidateService.name(this.form.name)) {
      this.error.name = true
    }

    if (!ValidateService.email(this.form.email)) {
      this.error.email = true
    }

    if (this.form.card.length !== 19) {
      this.error.card = true
    }

    if (!this.form.confirm) {
      this.error.confirm = true
    }

    if (!this.error.card && !this.error.email && !this.error.confirm && !this.error.name) {
      this.createTicket()
    }
  }

  clearErrors(): void {
    this.error.name = false
    this.error.email = false
    this.error.confirm = false
    this.error.card = false
  }

  reset(): void {
    this.isSanded = false
    this.clearErrors()

    this.form.name = ''
    this.form.email = ''
    this.form.card = ''
    this.form.comment = ''
    this.form.confirm = false
  }

  async createTicket(): Promise<void> {
    try {
      await apiTickets.create({
        domain: window.location.host,
        name: this.form.name,
        email: this.form.email,
        card: this.form.card,
        message: this.form.comment,
      })
    } finally {
      this.isSanded = true
    }
  }
}
</script>

<style lang="scss">
.form {
  width: 100%;
  max-width: 400px;
  padding: 32px 24px;
  border: 1.0552px solid #f2f5ff;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 11px 43px rgba(0, 22, 10, 0.1);

  &__title {
    margin-bottom: 16px;
    text-align: center;
    color: #4b4b4b;
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 16px;
  }

  &__label {
    margin-bottom: 4px;
    color: #4b4b4b;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  &__hint {
    margin-bottom: 4px;
    opacity: 0.7;
    color: #4b4b4b;
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
    line-height: 17px;
  }

  &__input {
    width: 100%;
    padding: 13px 8px;
    color: #4b4b4b;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    outline: none;
    background: #fafafa;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;

    &::placeholder {
      color: #bcbcbc;
      font-size: 17px;
      font-weight: 500;
      line-height: 22px;
    }

    &_error {
      border: 1px solid #e30707;
    }
  }

  &__error {
    opacity: 0.7;
    color: #e30707;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  &__btn {
    width: 100%;
    padding: 15px;
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 48px;
    background: #00997d;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;

    &:focus {
      outline: none;
    }
  }

  &__confirm {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    &_error {
      .form__checkbox {
        border: 2px solid #e30707;
      }

      .form__terms {
        opacity: 0.8;
        color: #e30707;
      }
    }
  }

  &__terms {
    opacity: 0.8;
    color: #4b4b4b;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
  }

  &__checkbox {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;
    border: 2px solid #00997d;
    border-radius: 4px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;

    &_active {
      background-color: #00997d;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.68421L6.41935 11L15 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
    }
  }
}

@media (max-width: 920px) {
  .form {
    padding: 24px 8px;

    &__title {
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 130%;
    }

    &__label {
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 18px;
    }

    &__hint {
      margin-bottom: 2px;
      font-size: 13px;
      line-height: 17px;
    }
  }
}
</style>
