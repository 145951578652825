<template>
  <div class="info">
    <div class="info__item">
      <div class="info__title">
        <img class="info__img" src="../assets/security.svg" alt="" />
        Security guarantee
      </div>
      <div class="info__text">
        PCI DSS guarantees safe processing, transfer, and keeping of information about payment cards
        Visa, MasterCard, Discover.
      </div>
    </div>
    <div class="info__item">
      <div class="info__title">
        <img class="info__img" src="../assets/support2.svg" alt="" />
        Support
      </div>
      <div class="info__text">
        If you cannot fill in the form for some reason, contact the Support.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Info extends Vue {}
</script>

<style lang="scss">
.info {
  width: 100%;
  max-width: 394px;
  margin-left: 160px;

  &__item {
    margin-bottom: 56px;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    color: #333;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }

  &__text {
    color: #525252;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }

  &__img {
    width: 56px;
    height: 56px;
    margin-right: 8px;
  }
}

@media (max-width: 920px) {
  .info {
    max-width: 400px;
    margin-left: 0;

    &__item {
      margin-bottom: 16px;
    }

    &__title {
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 24px;
    }

    &__text {
      font-size: 14px;
      line-height: 17px;
    }

    &__img {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }
}
</style>
