<template>
  <div class="page">
    <Header />

    <main class="content">
      <Form />

      <Info />

      <button class="support" data-test-id="click:open-support support" @click="openSupportChat">
        Support
        <img class="support__img" src="./assets/support.svg" alt="" />
      </button>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import ZendeskService from '@/services/ZendeskService'

import Form from './components/Form.vue'
import Header from './components/Header.vue'
import Info from './components/Info.vue'

@Component({
  components: {
    Header,
    Info,
    Form,
  },
})
export default class App extends Vue {
  async created(): Promise<void> {
    await ZendeskService.init()
  }

  openSupportChat(): void {
    ZendeskService.openChat()
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

#launcher {
  display: none;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #000;
  background: #fafafa;
  font-family: 'DM Sans', sans-serif;
}

.content {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 12px;
}

.support {
  position: fixed;
  right: 24px;
  bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 44px;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: #00997d;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;

  &:focus {
    outline: none;
  }

  &__img {
    margin-left: 6px;
  }
}

@media (max-width: 920px) {
  .content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 16px 12px 64px;
  }

  .support {
    right: 12px;
    bottom: 12px;
  }
}
</style>
